import React from "react"
import PropTypes from "prop-types"
import { graphql } from "gatsby"

import Layout from "../components/Layout"
import SEO from "../components/seo"
import NuvoImage from "../components/NuvoImage"
import { MarkdownViewer } from "../components/Markdown"
import { Section, Container } from "../components/Containers"
import { MapCTA } from "../components/CTA"

const About = ({ pageContext, data, location }) => {
  const post = data.allUniquePagesJson.nodes[0]

  return (
    <Layout>
      <SEO
        title={post.metaTitle}
        description={post.metaDescription}
        schemaPage={null}
        pathname={location.pathname}
      />

      <div className="about-page">
        <NuvoImage publicId={post.heroImage} />
        <Section>
          <Container>
            <MarkdownViewer markdown={post.content} />
          </Container>
        </Section>

        <MapCTA
          image={post.mapCta.image}
          // text={post.mapCta.text}
          button={post.mapCta.button}
        />
      </div>
    </Layout>
  )
}

About.propTypes = {
  pageContext: PropTypes.object,
  data: PropTypes.object
}

export const aboutPageQuery = graphql`
  query aboutQuery($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heroImage
        content
        mapCta {
          image
          text
          button {
            buttonText
            appearance
            destination
            href
          }
        }
      }
    }
  }
`

export default About
